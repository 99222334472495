import { useRef } from 'react';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
// import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';
import Image from '../Image/Image';
import styles from './HeroLandingPage.module.scss';

const HeroLandingPage = ({ title = '', image = {}, isErrorPage = false }) => {
    const { t } = useTranslation();
    const buttonRef = useRef(null);

    const hasImage = !!image.url && !!image.title;

    const scrollToContent = () => {
        window.scrollTo({
            top: buttonRef.current.offsetTop,
            behavior: 'smooth',
        });
    };

    return (
        <div className={styles['HeroLandingPage']}>
            <div
                className={classNames(styles['HeroLandingPage__Image'], {
                    [styles['HeroLandingPage__Image--ErrorPage']]: isErrorPage,
                })}>
                {hasImage && (
                    <Image {...{ image }} priority={true} loading={'eager'} />
                )}
            </div>
            <div className={styles['HeroLandingPage__Container']}>
                <h1 className={styles['HeroLandingPage__Title']}>{title}</h1>
                {!isErrorPage && (
                    <button
                        ref={buttonRef}
                        className={styles['HeroLandingPage__ScrollToContent']}
                        onClick={() => scrollToContent()}>
                        <Icon
                            icon={'chevronDown'}
                            dimensions={{ width: '34x', height: '34px' }}
                        />
                        <span className={'sr-only'}>
                            {t('Hero.scrollToContent')}
                        </span>
                    </button>
                )}
            </div>
        </div>
    );
};

HeroLandingPage.propTypes = {};

export default HeroLandingPage;
